import React from 'react';
import { ThreeDotsLoader } from 'wix-ui-tpa/cssVars';
import classes from './AdditionalDetailsLoader.css';

// TODO: Make skeleton loader
export const AdditionalDetailsLoader = () => (
  <div className={classes.loader}>
    <ThreeDotsLoader />
  </div>
);
