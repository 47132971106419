import React from 'react';
import { IconProps } from './types';

export const HollowCircleTick: React.FC<Partial<IconProps>> = ({ size = 38, className = '' }) => {
  return (
    <svg role="img" width={size} height={size} viewBox="0 0 24 24">
      <path
        className={className}
        fillRule="evenodd"
        d="M11.5 3C16.187 3 20 6.813 20 11.5C20 16.187 16.187 20 11.5 20C6.813 20 3 16.187 3 11.5C3 6.813 6.813 3 11.5 3ZM11.5 4C7.364 4 4 7.364 4 11.5C4 15.636 7.364 19 11.5 19C15.636 19 19 15.636 19 11.5C19 7.364 15.636 4 11.5 4ZM14.3012 9.0425L15.0082 9.7505L10.4482 14.3105L7.9912 11.8535L8.6982 11.1465L10.4482 12.8965L14.3012 9.0425Z"
      />
    </svg>
  );
};
