import React from 'react';
import { SectionNotification } from 'wix-ui-tpa/cssVars';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { Alert as AlertIcon } from '@wix/wix-ui-icons-common/on-stage';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import classes from './EditorPreviewNotification.css';

export const EditorPreviewNotification = () => {
  const { isPreview } = useEnvironment();
  const { t } = useTranslation();

  if (!isPreview) {
    return null;
  }

  return (
    <div className={classes.previewNotification}>
      <SectionNotification
        data-hook={VIEWER_HOOKS.PLAN_CUSTOMIZATION_EDITOR_PREVIEW_NOTIFICATION}
        type="alert"
        size="compact"
      >
        <SectionNotification.Icon icon={<AlertIcon />} />
        <SectionNotification.Text>
          {t('plan-customization.widget.editor-preview-notification')}
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  );
};
