import { EndType, Period, Plan } from '@wix/ambassador-pricing-plans-v3-plan/types';
import { FormattedAdditionalFee } from '@wix/pricing-plans-tpa-api';
import { CreateCurrencyFormatter, TFunction } from '@wix/yoshi-flow-editor';
import { getFormattedPriceData } from '../../../../hooks';

export function getFreeTrialDaysLabel(plan: Plan, t: TFunction) {
  const pricing = plan.pricingVariants?.[0];
  if (!pricing) {
    return '';
  }

  if (!pricing.freeTrialDays) {
    return '';
  }

  if (isUnlimitedPlan(plan)) {
    return t('pp.free-trial-days-unlimited', { count: pricing.freeTrialDays });
  }

  return t('pp.free-trial-days', { count: pricing.freeTrialDays });
}

export function getFormattedAdditionalFees(params: {
  plan: Plan;
  locale?: string;
  createCurrencyFormatter: CreateCurrencyFormatter;
  hideTrailingFractionZeroes?: boolean;
}): FormattedAdditionalFee[] {
  const { plan, locale, createCurrencyFormatter, hideTrailingFractionZeroes } = params;
  const pricing = plan.pricingVariants?.[0];
  if (!pricing || !pricing.fees?.length) {
    return [];
  }

  return pricing.fees.map((fee) => {
    const formatted = getFormattedPriceData({
      createCurrencyFormatter,
      locale,
      value: fee.fixedAmountOptions?.amount,
      currency: plan.currency,
      hideTrailingFractionZeroes,
    });

    return { name: fee.name!, amount: `${formatted.fullPrice}` };
  });
}

export function getPlanDuration(plan: Plan, t: TFunction) {
  if (isUnlimitedPlan(plan)) {
    return t('pp.plan-validity.valid-unlimited');
  } else {
    return getPlanDurationWithoutUnlimited(plan, t);
  }
}

const isUnlimitedPlan = (plan: Plan): boolean => {
  const pricing = plan.pricingVariants?.[0];
  return pricing?.billingTerms?.endType === EndType.UNTIL_CANCELLED;
};

function getPlanDurationWithoutUnlimited(plan: Plan, t: TFunction) {
  const pricing = plan.pricingVariants?.[0];
  const unit = pricing?.billingTerms?.billingCycle?.period;
  const planDuration = pricing?.billingTerms?.cyclesCompletedDetails?.billingCycleCount || 1;

  switch (unit) {
    case Period.DAY:
      return planDuration > 1
        ? t('pp.plan-validity.valid-for-days', { amount: planDuration })
        : t('pp.plan-validity.valid-for-one-day');
    case Period.WEEK:
      return planDuration > 1
        ? t('pp.plan-validity.valid-for-weeks', { amount: planDuration })
        : t('pp.plan-validity.valid-for-one-week');
    case Period.YEAR:
      return planDuration > 1
        ? t('pp.plan-validity.valid-for-years', { amount: planDuration })
        : t('pp.plan-validity.valid-for-one-year');
    default:
      return planDuration > 1
        ? t('pp.plan-validity.valid-for-months', { amount: planDuration })
        : t('pp.plan-validity.valid-for-one-month');
  }
}

export function getPeriodLabel(plan: Plan, t: TFunction) {
  const pricing = plan.pricingVariants?.[0];
  const unit = pricing?.billingTerms?.billingCycle?.period;
  const count = parseInt(pricing?.billingTerms?.billingCycle?.count ?? '', 10);

  if (!unit || !count) {
    return null;
  }

  switch (unit) {
    case Period.DAY:
      return t('pp.period-recurrency.every-day-plural', { count });
    case Period.WEEK:
      return t('pp.period-recurrency.every-week-plural', { count });
    case Period.YEAR:
      return t('pp.period-recurrency.every-year-plural', { count });
    case Period.MONTH:
      return t('pp.period-recurrency.every-month-plural', { count });
    default:
      return '';
  }
}
